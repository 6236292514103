import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, usePrefs } from 'wsm-common-data';
import { formatNumber, formatCurrency } from 'ddc-intl';
import isNil from '../util/isNil';

const VehicleCard = ({
	href,
	imageUrl,
	title,
	price,
	odometer,
	odometerUnit,
	condition,
	handleLinkClick,
	locale
}) => {
	const labels = useLabels();
	const { showPrice } = usePrefs();

	if (!title || !href || !imageUrl) {
		return null;
	}
	let displayTitle = title;
	if (title.length > 50) {
		displayTitle = `${title.substring(0, 50)}...`;
	}

	const displayOdometer = formatNumber(Number(odometer), locale) || '0';
	const displayOdometerUnit =
		odometer === '1' ? odometerUnit.slice(0, -1) : odometerUnit;

	// Need to remove the comma and dollar sign to format the price if not null/undefined
	const displayPrice = formatCurrency(
		Number(price.replace(/[$,]/g, '')),
		locale,
		false
	);
	const shouldDisplayPrice = !isNil(price) && showPrice === 'true';

	const handleClick = () => {
		handleLinkClick('vehicleLink', '', '', '');
	};

	return (
		<a
			href={href}
			className="d-flex border-bottom vehicle-card-horizontal list-group-item"
			aria-label={`${labels.get('VIEW_DETAILS_ABOUT')} ${title}`}
			onClick={handleClick}
		>
			<div
				data-testid="sts-vehicle-card"
				className="d-flex justify-content-end site-search-vehicle-card-media-container flex-grow-0 order-2"
			>
				<img
					className="img-responsive"
					src={`${imageUrl}?impolicy=downsize&w=130`}
					alt={title}
				/>
			</div>
			<div className="site-search-vehicle-card-details-container order-1 ddc-font-size-small p-1 line-height-condensed">
				<p className="mt-0 mb-0 ddc-font-size-xsmall opacity-75">
					{condition}
				</p>
				<p className="font-weight-normal mt-0 mb-0">{displayTitle}</p>
				{shouldDisplayPrice && (
					<p className="font-weight-bold mt-0 mb-0">{displayPrice}</p>
				)}
				{condition.toUpperCase() !== 'NEW' && (
					<p className="mt-0 mb-0 opacity-75">{`${displayOdometer} ${displayOdometerUnit}`}</p>
				)}
			</div>
		</a>
	);
};
VehicleCard.propTypes = {
	href: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	price: PropTypes.string,
	odometer: PropTypes.string,
	odometerUnit: PropTypes.string,
	condition: PropTypes.string,
	handleLinkClick: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired
};

VehicleCard.defaultProps = {
	price: '',
	odometer: '',
	odometerUnit: '',
	condition: ''
};

export default VehicleCard;
