import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs, useRequestData } from 'wsm-common-data';
import RichContent from './richContent/RichContent';
import useFocusTrap from '../hooks/useFocusTrap';
import InventoryTabs from './InventoryTabs';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../util/shouldShowRecentSearches';
import hasEmptyResults from '../util/hasEmptyResults';
import hasResults from '../util/hasResults';
import ContentColumnizer from './ContentColumnizer';
import useAriaLive from '../hooks/useAriaLive';
import isEnoughAddData from '../util/isEnoughAddData';

const DropDown = ({
	dropDownRef = React.createRef(),
	handleClearHistory,
	closeModal,
	dropDownPosition,
	inputRef,
	inputElementRef,
	inputWidth,
	isOpen,
	onRemove,
	recentSearches,
	onReorder,
	inventoryResults,
	pageResults,
	searchTerm,
	numberOfCharactersForFirstSearchNumber,
	handleChipClick,
	handleLinkClick,
	addInfo,
	richContentToDisplay,
	pageAlias,
	windowId,
	noResultsLinks,
	noResultsRichContent,
	disabledTabs,
	setActiveInventoryTab,
	activeInventoryTab
}) => {
	const { suggestions = undefined, vehicles = undefined } =
		inventoryResults || {};
	const labels = useLabels();
	const prefs = usePrefs();
	const { locale } = useRequestData();

	const chipAriaLabel = labels.get('SEARCH');
	const removeAriaLabel = labels.get('REMOVE');
	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(pageResults, suggestions, vehicles);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageResults,
		suggestions,
		vehicles
	);

	const announceText = useAriaLive(
		isSearchTermLongEnough,
		hasResultsLocal,
		hasEmptyResultsLocal
	);

	const hasRichContentToDisplay = isEnoughAddData(
		richContentToDisplay,
		addInfo
	);

	const shouldRenderNoResults =
		!hasResultsLocal && hasEmptyResultsLocal && !hasRichContentToDisplay;

	const richContent = shouldRenderNoResults
		? noResultsRichContent
		: richContentToDisplay;

	useFocusTrap(
		[inputRef, dropDownRef],
		dropDownRef,
		null,
		inputElementRef,
		null,
		isOpen,
		closeModal
	);

	const classNames = [
		'sts-search-dropdown',
		'ddc-box-1',
		'bg-contrast-low',
		'p-4',
		!isOpen && 'hidden',
		'shadow'
	];

	if (
		!recentSearches?.length &&
		(!searchTerm ||
			!isSearchTermLongEnough ||
			(pageResults === undefined &&
				suggestions === undefined &&
				vehicles === undefined))
	) {
		return null;
	}

	// if first SRP path suggestion has more than one vehicle, assign value
	const allVehiclesPath =
		suggestions && suggestions?.[0]?.count > 1
			? suggestions?.[0]?.path
			: '';
	const viewLinkLabel = labels.get('VIEW_ALL_VEHICLES_SITE_SEARCH');
	const handleClick = () => {
		handleLinkClick('allInventoryLink', viewLinkLabel, '', '');
	};

	const dropDownContent = (
		<div
			ref={dropDownRef}
			className={setClassNames(classNames)}
			data-testid="sts-input-dropdown"
			data-widget-name="ws-site-text-search"
			role="dialog"
			aria-labelledby={
				searchTerm ? 'results-heading' : 'recent-searches-heading'
			}
			aria-modal="true"
			style={{
				position: 'absolute',
				top: dropDownPosition.top,
				left: dropDownPosition.left,
				width: inputWidth,
				minHeight: '117px'
			}}
		>
			<div aria-live="assertive" className="sr-only">
				{announceText}
			</div>

			{shouldShowRecentSearches(
				searchTerm,
				hasResultsLocal,
				hasEmptyResultsLocal,
				recentSearches
			) && (
				<RecentSearches
					recentSearches={recentSearches}
					handleClearHistory={handleClearHistory}
					chipAriaLabel={chipAriaLabel}
					removeAriaLabel={removeAriaLabel}
					onRemove={onRemove}
					onReorder={onReorder}
					handleChipClick={handleChipClick}
				/>
			)}

			{isSearchTermLongEnough && (
				<div>
					<h3 id="results-heading" className="sr-only">
						{labels.get('SEARCH_RESULTS')}
					</h3>
					<InventoryTabs
						activeInventoryTab={activeInventoryTab}
						setActiveInventoryTab={setActiveInventoryTab}
						disabledTabs={disabledTabs}
						showTabs={
							vehicles?.length > 0 ||
							suggestions?.length ||
							pageResults?.length ||
							shouldRenderNoResults ||
							hasRichContentToDisplay
						}
					>
						{allVehiclesPath && (
							<a
								onClick={handleClick}
								href={allVehiclesPath}
								className="font-weight-bold py-3 px-4"
							>
								<span>
									{viewLinkLabel}
									<i
										className="ddc-icon ddc-icon-arrow-details ml-3"
										aria-hidden="true"
									/>
								</span>
							</a>
						)}
						<ContentColumnizer
							inputWidth={inputWidth}
							vehicles={vehicles?.length > 0}
						>
							{vehicles?.length > 0 ? (
								<VehicleCards
									vehicles={vehicles}
									handleLinkClick={handleLinkClick}
									inputWidth={inputWidth}
									locale={locale}
								/>
							) : null}
							{suggestions?.length > 0 ? (
								<SearchLinks
									type="inventory"
									showResults={prefs.showInventoryResults}
									data={suggestions}
									iconClass="ddc-icon-single-vehicle"
									ariaLabel={labels.get(
										'INVENTORY_SEARCH_RESULTS'
									)}
									handleLinkClick={handleLinkClick}
								/>
							) : null}
							{pageResults?.length > 0 ? (
								<SearchLinks
									type="page"
									showResults={prefs.showPageSearchResults}
									data={pageResults}
									iconClass="ddc-icon-arrow-circle-right"
									ariaLabel={labels.get(
										'PAGE_SEARCH_RESULTS'
									)}
									handleLinkClick={handleLinkClick}
								/>
							) : null}
							{shouldRenderNoResults && (
								<>
									<NoResults />
									<SearchLinks
										type="no-results"
										showResults="true"
										data={noResultsLinks}
										ariaLabel={labels.get(
											'PAGE_SEARCH_RESULTS'
										)}
										handleLinkClick={handleLinkClick}
									/>
								</>
							)}
							{hasRichContentToDisplay && (
								<RichContent
									addInfo={addInfo}
									richContentToDisplay={richContent}
									pageAlias={pageAlias}
									windowId={windowId}
									handleLinkClick={handleLinkClick}
								/>
							)}
						</ContentColumnizer>
					</InventoryTabs>
				</div>
			)}
		</div>
	);
	return ReactDOM.createPortal(dropDownContent, document.body);
};
DropDown.propTypes = {
	addInfo: PropTypes.shape(),
	dropDownPosition: PropTypes.shape({
		top: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired
	}).isRequired,
	handleChipClick: PropTypes.func,
	handleLinkClick: PropTypes.func,
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputWidth: PropTypes.number.isRequired,
	inventoryResults: PropTypes.shape({}),
	isOpen: PropTypes.bool.isRequired,
	noResultsLinks: PropTypes.arrayOf(PropTypes.shape({})),
	noResultsRichContent: PropTypes.arrayOf(PropTypes.string),
	numberOfCharactersForFirstSearchNumber: PropTypes.number,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	pageAlias: PropTypes.string,
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	richContentToDisplay: PropTypes.arrayOf(PropTypes.string),
	searchTerm: PropTypes.string,
	windowId: PropTypes.string,
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	dropDownRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	handleClearHistory: PropTypes.func,
	closeModal: PropTypes.func,
	disabledTabs: PropTypes.arrayOf(PropTypes.string),
	activeInventoryTab: PropTypes.bool,
	setActiveInventoryTab: PropTypes.func,
	showTabs: PropTypes.bool
};

export default DropDown;
