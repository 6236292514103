import React from 'react';
import PropTypes from 'prop-types';

const ContentColumnizer = ({ children, inputWidth, vehicles }) => {
	const cleanedChildren = children.filter((n) => n);
	const singleColumnWidth = 600;
	const isDoubleColumn = inputWidth > singleColumnWidth;
	const className = isDoubleColumn ? 'col-sm-6' : 'col-sm-12';

	function sortContent() {
		const leftColumn = [];
		const rightColumn = [];
		cleanedChildren.forEach((child, index) => {
			if (vehicles && child?.props?.vehicles && isDoubleColumn) {
				leftColumn.push(child);
			} else if (!vehicles && index % 2 === 0 && isDoubleColumn) {
				leftColumn.push(child);
			} else {
				rightColumn.push(child);
			}
		});
		return (
			<>
				<div className={className} data-testid="sts-input-column-one">
					{leftColumn}
				</div>
				<div className={className} data-testid="sts-input-column-two">
					{rightColumn}
				</div>
			</>
		);
	}

	return <div className="row">{sortContent()}</div>;
};

ContentColumnizer.propTypes = {
	children: PropTypes.node,
	inputWidth: PropTypes.number,
	vehicles: PropTypes.bool
};

export default ContentColumnizer;
