import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { useRequestData } from 'wsm-common-data';
import truncateValue from '../util/truncateValue';

const Chip = ({
	recentSearch,
	btnLabel,
	removeLabel,
	onRemove,
	onReorder,
	handleChipClick
}) => {
	const { windowId, widgetName } = useRequestData();
	const handleRemove = (e) => {
		e.stopPropagation();
		onRemove(recentSearch.value);
		trackEvent(widgetName, windowId, {
			element: 'search box x',
			elementCTA: recentSearch.value,
			result: 'chip removed',
			action: 'clicked'
		});
	};

	const handleClick = () => {
		handleChipClick(recentSearch.value);
		onReorder(recentSearch);
		trackEvent(widgetName, windowId, {
			element: 'search chip',
			elementCTA: recentSearch.value,
			result: 'text search form re-submitted',
			action: 'clicked'
		});
	};

	return (
		<button
			className="chip chip-default mr-3 mt-3 ddc-font-size-small"
			type="button"
			role="menuitem"
			aria-label={`${btnLabel}: ${recentSearch.value}`}
			tabIndex={0}
			onClick={handleClick}
		>
			{truncateValue(recentSearch.value)}
			<span
				role="button"
				tabIndex={0}
				className="ddc-icon ddc-icon-remove2 icon-size-2 ml-2"
				onClick={handleRemove}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						handleRemove(e);
					}
				}}
				aria-label={`${removeLabel}: ${recentSearch.value}`}
				data-testid="remove-icon"
			/>
		</button>
	);
};

Chip.propTypes = {
	recentSearch: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string
	}),
	btnLabel: PropTypes.string,
	removeLabel: PropTypes.string,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	handleChipClick: PropTypes.func
};

export default Chip;
