import React from 'react';
import PropTypes from 'prop-types';
import { useRequestData, usePrefs, useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { g } from '../global-constants';

const InputDisplay = ({
	inputRef = React.createRef(),
	inputElementRef = React.createRef(),
	openModal,
	isOpen,
	onClear,
	type,
	handleSubmission,
	searchTerm,
	searchTermEmbedded,
	handleOnChangeInput,
	onInputHandler,
	isEmbeddedOverrideActive
}) => {
	const { deviceType } = useRequestData();
	const isMobile = deviceType === g.MOBILE;
	const labels = useLabels();
	const { searchPlaceholderLabel } = usePrefs();
	const { windowId, widgetName } = useRequestData();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const inputId = `${pageAlias}_${windowId}`;

	const inputClassNames = [
		'form-control',
		'site-text-search-input',
		'font-size-ios-zoom-override',
		'form-control-transparent',
		'pl-0'
	];
	const clearClassNames = [
		'btn-unstyled text-link-muted',
		'py-3',
		'px-4',
		'btn-sm',
		'border-neutral-0-alpha-3',
		!isOpen && 'hidden'
	];

	const inputContainerClassNames = [
		'input-container text-left',
		(searchTerm || searchTermEmbedded) && 'has-content'
	];

	return (
		<>
			<div
				ref={inputRef}
				data-type={type}
				data-testid="sts-input-display"
				className="site-text-search-wrapper"
			>
				<form
					className="form-control spacing-reset d-flex h-auto"
					autoComplete="off"
					data-form-tracking-id="SITEWIDE_SEARCH"
					data-form-tracking-type="typedSearch"
					role="search"
					onSubmit={(e) => handleSubmission(e)}
				>
					{/* eslint-disable jsx-a11y/click-events-have-key-events */}
					<span className="ml-3 mr-3 my-3">
						{/* eslint-disable jsx-a11y/click-events-have-key-events */}
						<i
							className="ddc-icon ddc-icon-search icon-size-2"
							aria-hidden="true"
						/>
					</span>
					<div className={setClassNames(inputContainerClassNames)}>
						<label
							id="site-search-label"
							htmlFor={inputId}
							className="floating-placeholder"
						>
							{labels.get(searchPlaceholderLabel)}
						</label>
						<input
							id={inputId}
							className={setClassNames(inputClassNames)}
							aria-label={labels.get('SEARCH_BY_MAKE_MODEL_ETC')}
							onClick={() => {
								openModal?.();
								onInputHandler?.();
							}}
							value={
								isEmbeddedOverrideActive
									? searchTerm
									: searchTermEmbedded
							}
							onFocus={() => {
								if (!isMobile) {
									openModal?.();
									onInputHandler?.();
								}
							}}
							onChange={(e) =>
								handleOnChangeInput(e.target.value)
							}
							maxLength={100}
							data-testid="sts-input"
							ref={inputElementRef}
						/>
					</div>
					{!(type === g.EMBEDDED && !isEmbeddedOverrideActive) && (
						<button
							type="button"
							tabIndex="0"
							aria-label={labels.get('CLEAR')}
							className={setClassNames(clearClassNames)}
							onClick={() => {
								trackEvent(widgetName, windowId, {
									action: 'clicked',
									element: 'clear button',
									result: 'search field cleared'
								});
								onClear();
								inputElementRef.current?.focus();
								// Place cursor at the end of empty input
								inputElementRef.current?.setSelectionRange(
									inputElementRef.current.value.length,
									inputElementRef.current.value.length
								);
							}}
							data-testid="clear-button"
						>
							{labels.get('CLEAR')}
						</button>
					)}
				</form>
			</div>
		</>
	);
};

InputDisplay.propTypes = {
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputElementRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	openModal: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	onClear: PropTypes.func.isRequired,
	type: PropTypes.string,
	handleSubmission: PropTypes.func,
	searchTerm: PropTypes.string,
	searchTermEmbedded: PropTypes.string,
	handleOnChangeInput: PropTypes.func,
	onInputHandler: PropTypes.func,
	isEmbeddedOverrideActive: PropTypes.bool
};

export default InputDisplay;
