import React, {
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback
} from 'react';
import {
	useFlags,
	usePrefs,
	useSitemap,
	useRequestData,
	useLabels
} from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useSelector } from 'react-redux';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { trackEvent } from 'ddc-track-event';
import throttle from '../util/throttle';
import closeModalHelper from '../util/closeModalHelper';
import { g } from '../global-constants';
import DropDown from '../components/DropDown';
import InputDisplay from '../components/InputDisplay';
import fetchInventory from '../util/fetchInventory';
import fetchPages from '../util/fetchPages';
import transformInventoryResults from '../util/transformInventoryResults';
import getRichContentResults from '../util/getRichContentResults';
import ButtonDisplay from '../components/ButtonDisplay';
import FlyOut from '../components/FlyOut';
import StaticEmbedded from '../components/StaticEmbedded';

const Container = ({ type, isMobile }) => {
	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
	}

	const isEmbedded = type === g.EMBEDDED;
	const labels = useLabels();
	const flags = useFlags();
	const sitemap = useSitemap();
	const {
		numberOfCharactersForFirstSearch,
		overrideAllInventoryAlias,
		showInventoryResults,
		showHoursResults,
		showAddressResult,
		showEmailResult,
		showPhoneNumberResults,
		noResultsLinks,
		vehicleOrder
	} = usePrefs();

	const numberOfCharactersForFirstSearchNumber = parseInt(
		numberOfCharactersForFirstSearch,
		10
	);

	const { windowId, locale, widgetName } = useRequestData();
	const inputRef = useRef(null);
	const dropDownRef = useRef(null);
	const embeddedDropDownRef = useRef(null);
	const openButtonRef = useRef(null);
	const inputElementRef = useRef(null);

	const {
		accountId,
		siteId,
		widgetSearchServiceUri,
		configTypes,
		pageAlias
	} = useSelector((state) => state.widgetData);

	const [dropDownPosition, setDropDownPosition] = useState({
		top: 0,
		left: 0
	});

	const [inputWidth, setInputWidth] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchTermEmbedded, setSearchTermEmbedded] = useState('');
	const [inventorySearchResults, setInventorySearchResults] = useState();
	const [pageSearchResults, setPageSearchResults] = useState();
	const [pageSearchResultsEmbedded, setPageSearchResultsEmbedded] =
		useState();
	const [addInfo, setAddInfo] = useState();
	const [richContentToDisplay, setRichContentToDisplay] = useState([]);
	const [richContentToDisplayEmbedded, setRichContentToDisplayEmbedded] =
		useState([]);
	const [fetchingResults, setFetchingResults] = useState(false);
	const [screenWidth, setScreenWidth] = useState(0);
	const [isEmbeddedOverrideActive, setIsEmbeddedOverrideActive] =
		useState(false);
	const noResultsRichContent = ['phone-sales', 'hours-sales', 'email'];
	const [activeInventoryTab, setActiveInventoryTab] = useState('new');
	const [disabledTabs, setDisabledTabs] = useState([]);

	const filteredInventorySearchResultsByTab =
		flags['enable-conditions-tabs-site-text-search'] === true
			? {
					...inventorySearchResults,
					vehicles: inventorySearchResults?.vehicles?.filter(
						(vehicle) => vehicle.condition === activeInventoryTab
					)
			  }
			: inventorySearchResults;

	const hasSearchResults =
		pageSearchResults?.length > 0 ||
		inventorySearchResults?.suggestions?.length > 0 ||
		inventorySearchResults?.vehicles?.length > 0;

	const NO_RESULTS_LINKS = useMemo(() => {
		return noResultsLinks.split(',').map((item) => {
			const trimmedItem = item.trim();
			return {
				alias: trimmedItem,
				title: labels.get(`${trimmedItem}_SEARCH_LINK`),
				path: sitemap.get(trimmedItem),
				icon: trimmedItem.startsWith('INVENTORY_LISTING')
					? 'ddc-icon-single-vehicle'
					: 'ddc-icon-arrow-circle-right'
			};
		});
	}, [noResultsLinks, labels, sitemap]);

	const fetchAddInfo = useCallback(async () => {
		if (typeof window !== 'undefined' && !addInfo) {
			// this code eventually used for wsm-contact-transformer which looks for 'iPhone'
			// in userAgent so doing a tricky thing so we do not have to pass whole userAgent

			const userAgent = window.navigator.userAgent.includes('iPhone')
				? 'iPhone'
				: 'not-apple-mobile-device';

			const fetchedAddInfo = await fetch(
				`${widgetSearchServiceUri}/addData/${accountId}/${userAgent}/${siteId}/${locale}/${widgetName}`
			);
			const info = await fetchedAddInfo.json();

			setAddInfo(info);
		}
	}, [
		accountId,
		addInfo,
		locale,
		siteId,
		widgetName,
		widgetSearchServiceUri
	]);

	const updateDropDownPosition = useCallback(() => {
		if (inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			const newTop = inputRect.bottom + window.scrollY;
			const newLeft = inputRect.left + window.scrollX;

			// Ensure the dropdown's top position is always below the input display's bottom position
			const minTop = inputRect.bottom + window.scrollY;

			const adjustedTop = Math.max(newTop, minTop);

			setDropDownPosition({
				top: adjustedTop,
				left: newLeft
			});
			setInputWidth(inputRect.width);
		}
	}, [inputRef]);

	const getInitialRecentSearches = useCallback(() => {
		if (typeof localStorage === 'undefined') {
			return [];
		}
		try {
			const storedSearches = localStorage.getItem(g.RECENT_SEARCHES_KEY);
			return storedSearches ? JSON.parse(storedSearches) : [];
		} catch (error) {
			return [];
		}
	}, []);

	const [recentSearches, setRecentSearches] = useState(
		getInitialRecentSearches
	);

	const RESULTS_PAGE_ALIAS = 'SITE_TEXT_SEARCH_RESULTS';
	const FOUR_ZERO_FOUR_PAGE_ALIAS = '404';
	const pageNotFoundPath = sitemap.get(FOUR_ZERO_FOUR_PAGE_ALIAS);
	const resultsPageAlias =
		sitemap.get(RESULTS_PAGE_ALIAS) || pageNotFoundPath;

	const inventorySrpPath =
		(overrideAllInventoryAlias
			? sitemap.get(overrideAllInventoryAlias)
			: sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_ALL')) ||
		pageNotFoundPath;

	const hasFetchedAddInfo = useRef(false);

	const openModal = useCallback(() => {
		if (!hasFetchedAddInfo.current) {
			fetchAddInfo();
			hasFetchedAddInfo.current = true;
		}
		if (
			(!modalIsOpen &&
				!window?.DDC?.WidgetData?.[widgetName]?.modalIsOpen &&
				(recentSearches?.length > 0 ||
					hasSearchResults ||
					searchTerm?.length >
						numberOfCharactersForFirstSearchNumber)) ||
			type !== 'input' ||
			richContentToDisplay.length > 0 ||
			isMobile
		) {
			window.DDC.WidgetData[widgetName] = {
				modalIsOpen: true
			};
			trackEvent(widgetName, windowId, {
				element: 'search box',
				result: 'search modal initiated',
				action: 'clicked'
			});
			setRecentSearches(getInitialRecentSearches);
			setNewRelicCustomAttribute('wsSiteTextSearch-modalOpened', 'true');
			setModalIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen, widgetName, searchTerm]);

	const closeModal = useCallback(() => {
		closeModalHelper(
			widgetName,
			windowId,
			setModalIsOpen,
			setSearchTerm,
			isEmbeddedOverrideActive,
			setIsEmbeddedOverrideActive
		);
	}, []);

	const updateRecentSearches = useCallback(() => {
		const sanitizedTerm = searchTerm?.trim()?.substring(0, 100);
		if (!sanitizedTerm) {
			return;
		}
		try {
			const searches =
				JSON.parse(localStorage.getItem(g.RECENT_SEARCHES_KEY)) || [];
			const filteredSearch = searches.filter(
				(search) => search.value !== sanitizedTerm
			);

			if (filteredSearch.length >= 10) {
				filteredSearch.pop();
			}

			const newSearch = { value: sanitizedTerm };
			const newRecentSearches = [newSearch, ...filteredSearch];
			localStorage.setItem(
				g.RECENT_SEARCHES_KEY,
				JSON.stringify(newRecentSearches)
			);
			setRecentSearches(newRecentSearches);
		} catch (error) {
			// add New Relic error tracking
		}
	}, [searchTerm]);

	const removeRecentSearch = useCallback((value) => {
		setRecentSearches((prevState) => {
			const updatedSearches = prevState.filter(
				(search) => search.value !== value
			);
			try {
				localStorage.setItem(
					g.RECENT_SEARCHES_KEY,
					JSON.stringify(updatedSearches)
				);
			} catch (error) {
				// add New Relic error tracking
			}

			if (updatedSearches.length === 0) {
				inputElementRef.current?.focus();
			}

			return updatedSearches;
		});
	}, []);

	const reorderRecentSearches = useCallback(
		(clickedSearch) => {
			const updatedSearches = recentSearches.filter(
				(search) => search.value !== clickedSearch.value
			);
			const newRecentSearches = [clickedSearch, ...updatedSearches];

			setRecentSearches(newRecentSearches);

			try {
				localStorage.setItem(
					g.RECENT_SEARCHES_KEY,
					JSON.stringify(newRecentSearches)
				);
			} catch (error) {
				// Add New Relic error tracking
			}
		},
		[recentSearches]
	);

	const getPageResults = useCallback(
		async (query) => {
			if (showInventoryResults !== 'true') return undefined;

			const limit = type === 'embedded' ? 25 : 5;
			const pageSearchResponse = await fetchPages({
				searchTerm: query,
				siteId,
				locale,
				widgetSearchServiceUri,
				limit
			});

			return pageSearchResponse?.sort((a, b) => b.score - a.score);
		},
		[showInventoryResults, type, siteId, locale, widgetSearchServiceUri]
	);

	const firstRender = useRef(true);
	const getInventoryResults = useCallback(
		async (query) => {
			if (type === 'embedded' && firstRender.current) {
				firstRender.current = false;
				return undefined;
			}

			const inventoryFetchTimeout =
				flags['ws-site-text-search-inventory-client-timeout'];

			if (showInventoryResults !== 'true') return undefined;

			const inventorySearchResponse = await fetchInventory({
				searchTerm: query,
				siteId,
				locale,
				widgetSearchServiceUri,
				configTypes,
				resultsPageAlias,
				inventoryFetchTimeout,
				shouldReturnCardsAlso: true,
				vehicleOrder
			});
			if (inventorySearchResponse) {
				const {
					suggestions,
					vehicles,
					searchTerm: responseSearchTerm
				} = inventorySearchResponse;

				if (responseSearchTerm === query.replace('+', ' ')) {
					let transformedSuggestions = [];
					if (suggestions && suggestions.length) {
						transformedSuggestions = transformInventoryResults(
							suggestions,
							inventorySrpPath
						);
					}
					return {
						suggestions: transformedSuggestions?.sort(
							(a, b) => b.count - a.count
						),
						vehicles
					};
				}
			}
			return [];
			// eslint-disable-next-line max-len
		},
		[
			type,
			firstRender,
			flags,
			showInventoryResults,
			siteId,
			locale,
			widgetSearchServiceUri,
			configTypes,
			resultsPageAlias,
			vehicleOrder,
			inventorySrpPath
		]
	);
	const handleTabs = (inventoryResponse) => {
		const tabs = ['new', 'used', 'certified'];
		const tabsToDisable = tabs.filter(
			(condition) =>
				!inventoryResponse?.vehicles?.some(
					(item) => item.condition === condition
				)
		);
		setDisabledTabs(tabsToDisable);

		const activeTab = tabs.find((tab) => !tabsToDisable.includes(tab));

		setActiveInventoryTab(activeTab || 'new');
	};

	const debounce =
		typeof window !== 'undefined' && window._ && window._.debounce
			? window._.debounce
			: function debounce(action, wait) {
					let timeoutId;
					return function debouncer() {
						clearTimeout(timeoutId);
						timeoutId = setTimeout(action, wait);
					};
			  };
	const debouncedFetch = useCallback(
		debounce(async (query) => {
			const [pageSearchResponse, inventorySearchResponse] =
				await Promise.all([
					getPageResults(query),
					getInventoryResults(query)
				]);

			setPageSearchResults(pageSearchResponse);

			const inventoryResponse = Array.isArray(inventorySearchResponse)
				? { results: inventorySearchResponse }
				: inventorySearchResponse;

			setInventorySearchResults(inventoryResponse);

			handleTabs(inventoryResponse);

			const richContentToHide = [];
			if (showPhoneNumberResults === 'false') {
				richContentToHide.push(
					'phone-sales',
					'phone-service',
					'phone-parts',
					'phone-commercial'
				);
			}
			if (showEmailResult === 'false') {
				richContentToHide.push('email');
			}
			if (showAddressResult === 'false') {
				richContentToHide.push('address');
			}
			if (showHoursResults === 'false') {
				richContentToHide.push(
					'hours-sales',
					'hours-service',
					'hours-parts',
					'hours-commercial'
				);
			}
			const richContentResults = await getRichContentResults(
				query,
				numberOfCharactersForFirstSearchNumber,
				richContentToHide,
				locale
			);
			setRichContentToDisplay(richContentResults);

			if (query.length >= numberOfCharactersForFirstSearchNumber) {
				setModalIsOpen(true);
			}

			const resultTypes = [];
			if (
				inventorySearchResponse?.suggestions?.length ||
				inventorySearchResponse?.vehicles?.length
			) {
				resultTypes.push('Inventory Results');
			}
			if (pageSearchResponse?.length) {
				resultTypes.push('Non-Inventory Results');
			}
			const result = resultTypes.length
				? `${resultTypes.join(' and ')} Populated`
				: 'No Results Populated';
			trackEvent(widgetName, windowId, {
				element: 'search query typed in',
				result,
				action: 'impression',
				fieldValue: query,
				nonInteractive: false
			});

			setFetchingResults(false);
		}, 250),
		[
			getPageResults,
			getInventoryResults,
			showPhoneNumberResults,
			showEmailResult,
			showAddressResult,
			showHoursResults,
			numberOfCharactersForFirstSearchNumber,
			locale,
			widgetName,
			windowId
		]
	);

	const hasNoResults = !!(
		searchTerm &&
		pageSearchResults?.length === 0 &&
		inventorySearchResults?.vehicles?.length === 0 &&
		richContentToDisplay?.length === 0
	);

	const debouncedFetchEmbedded = useCallback(
		debounce(async (query) => {
			const [pageSearchResponse] = await Promise.all([
				getPageResults(query),
				getInventoryResults(query)
			]);

			setPageSearchResultsEmbedded(pageSearchResponse);

			const richContentToHide = [];
			if (showPhoneNumberResults === 'false') {
				richContentToHide.push(
					'phone-sales',
					'phone-service',
					'phone-parts',
					'phone-commercial'
				);
			}
			if (showEmailResult === 'false') {
				richContentToHide.push('email');
			}
			if (showAddressResult === 'false') {
				richContentToHide.push('address');
			}
			if (showHoursResults === 'false') {
				richContentToHide.push(
					'hours-sales',
					'hours-service',
					'hours-parts',
					'hours-commercial'
				);
			}
			const richContentResults = await getRichContentResults(
				query,
				numberOfCharactersForFirstSearchNumber,
				richContentToHide,
				locale
			);
			setRichContentToDisplayEmbedded(richContentResults);
		}, 250),
		[]
	);

	const handleLinkClick = useCallback(
		(
			linkType,
			elementCTA,
			fieldValue,
			result,
			addRecentSearches = true
		) => {
			if (addRecentSearches) {
				updateRecentSearches();
			}

			switch (linkType) {
				case 'vehicleLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'vehicle card',
						elementCTA: 'autocomplete vehicle card',
						result: 'navigated to VDP',
						fieldValue: searchTerm
					});
					break;
				case 'richContentLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'rich content autocomplete result',
						elementCTA: elementCTA || '',
						result:
							result || 'navigated to rich content destination',
						fieldValue: searchTerm
					});
					break;
				case 'inventoryLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'autocomplete inventory result',
						elementCTA: elementCTA || '',
						result: 'text search form submitted',
						fieldValue: fieldValue || ''
					});
					break;
				case 'pageLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'autocomplete quick link result',
						elementCTA: elementCTA || '',
						result: 'text search form submitted',
						fieldValue: fieldValue || ''
					});
					break;
				case 'allInventoryLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'All inventory link',
						elementCTA: elementCTA || '',
						result: 'text search form submitted',
						fieldValue: searchTerm
					});
					break;
				case 'noResultsLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'No results link',
						elementCTA: elementCTA || '',
						result: 'Navigated to CTA destination',
						fieldValue: searchTerm
					});
					break;
				default:
					break;
			}
		},
		[updateRecentSearches, widgetName, windowId, searchTerm]
	);

	const handleQuerySubmission = useCallback(
		(event) => {
			event?.preventDefault();
			if (fetchingResults) return;
			setNewRelicCustomAttribute('wsSiteTextSearch-onSubmit', 'true');

			if (
				inventorySearchResults?.vehicles?.length === 1 &&
				inventorySearchResults?.vehicles[0]?.path
			) {
				const { path: singleVehiclePath } =
					inventorySearchResults.vehicles[0];
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitSingleVehicleCard',
					'true'
				);
				handleLinkClick('vehicleLink', '', '', '');
				window.location.href = singleVehiclePath || null;
			} else if (hasNoResults) {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitNoResultsNotRedirected',
					'true'
				);
				event.preventDefault();
			} else if (
				inventorySearchResults?.suggestions &&
				inventorySearchResults.suggestions.length
			) {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitRedirectedToAllInventory',
					'true'
				);
				window.location.href = `${inventorySrpPath}?search=${encodeURIComponent(
					searchTerm
				)}`;
			} else {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitRedirectedToSearchResults',
					'true'
				);
				window.location.href = `${resultsPageAlias}?search=${encodeURIComponent(
					searchTerm
				)}`;
			}
		},
		[
			fetchingResults,
			inventorySearchResults,
			hasNoResults,
			handleLinkClick,
			inventorySrpPath,
			searchTerm,
			resultsPageAlias
		]
	);

	const handleSubmission = useCallback(
		(event) => {
			event?.preventDefault();
			if (
				!searchTerm?.trim() ||
				searchTerm.length < numberOfCharactersForFirstSearchNumber
			)
				return;
			setNewRelicCustomAttribute('wsSiteTextSearch-enterKey', searchTerm);
			if (!hasNoResults) {
				updateRecentSearches(searchTerm);
			}
			handleQuerySubmission(event);
			if (!modalIsOpen) {
				setModalIsOpen(true);
			}
		},
		[
			searchTerm,
			numberOfCharactersForFirstSearchNumber,
			hasNoResults,
			updateRecentSearches,
			handleQuerySubmission,
			modalIsOpen
		]
	);

	const handleClearHistory = useCallback(() => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
		setModalIsOpen(false);
		trackEvent(widgetName, windowId, {
			element: 'clear history link',
			elementCTA: labels.get('CLEAR_HISTORY'),
			result: 'all chips removed',
			action: 'clicked'
		});
	}, [widgetName, windowId, labels]);

	const handleClearHistoryNoClose = useCallback(() => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
		trackEvent(widgetName, windowId, {
			element: 'clear history link',
			elementCTA: labels.get('CLEAR_HISTORY'),
			result: 'all chips removed',
			action: 'clicked'
		});
	}, [widgetName, windowId, labels]);

	const isClickOutside = useCallback(
		(ref, target) => ref?.current && !ref.current.contains(target),
		[]
	);
	const isClickInside = useCallback(
		(ref, target) => ref?.current && ref.current.contains(target),
		[]
	);

	const handleClickOutside = useCallback(
		(event) => {
			const { target } = event;

			const isOutsideInput = isClickOutside(inputRef, target);
			const isOutsideDropdown = isClickOutside(dropDownRef, target);
			const isInsideEmbeddedDropdown = isClickInside(
				embeddedDropDownRef,
				target
			);
			const isOutsideEmbeddedDropdown = isClickOutside(
				embeddedDropDownRef,
				target
			);

			if (
				(isOutsideInput && isOutsideDropdown) ||
				(isEmbedded && isOutsideInput && isInsideEmbeddedDropdown) ||
				(isOutsideInput &&
					isOutsideEmbeddedDropdown &&
					!dropDownRef.current?.contains(event.target))
			) {
				closeModal();
			}
		},
		[
			isClickOutside,
			isClickInside,
			inputRef,
			dropDownRef,
			embeddedDropDownRef,
			isEmbedded,
			closeModal
		]
	);

	const handleOnChangeInput = useCallback(
		(searchTermValue) => {
			setSearchTerm(searchTermValue);
			if (searchTermValue >= numberOfCharactersForFirstSearchNumber) {
				setModalIsOpen(true);
			} else if (searchTermValue === 0 && recentSearches.length === 0) {
				setModalIsOpen(false);
			}
		},
		[numberOfCharactersForFirstSearchNumber, recentSearches.length]
	);

	const handleChipClick = useCallback((value) => {
		setSearchTerm(value);
		inputElementRef.current?.focus();
	}, []);

	useEffect(() => {
		const updateScreenWidth = () => setScreenWidth(window.innerWidth);
		updateScreenWidth();
		const throttledUpdateDropDownPosition = throttle(
			updateDropDownPosition,
			150
		);
		const throttledUpdateScreenWidth = throttle(updateScreenWidth, 150);
		const observer = new MutationObserver(throttledUpdateDropDownPosition);

		if (modalIsOpen) {
			updateDropDownPosition();
			window.addEventListener('resize', throttledUpdateDropDownPosition);
			window.addEventListener('resize', throttledUpdateScreenWidth);
			window.addEventListener('scroll', throttledUpdateDropDownPosition);
			document.addEventListener('mousedown', handleClickOutside);

			observer.observe(document.body, {
				childList: true,
				subtree: true,
				attributes: true
			});
		}
		return () => {
			window.removeEventListener(
				'resize',
				throttledUpdateDropDownPosition
			);
			window.removeEventListener(
				'scroll',
				throttledUpdateDropDownPosition
			);
			window.removeEventListener('resize', throttledUpdateScreenWidth);
			throttledUpdateDropDownPosition.cancel();
			document.removeEventListener('mousedown', handleClickOutside);
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen]);

	useEffect(() => {
		const trimmedSearchTerm = searchTerm.trim();

		if (
			trimmedSearchTerm?.length >= numberOfCharactersForFirstSearchNumber
		) {
			setFetchingResults(true);
			debouncedFetch(trimmedSearchTerm);
		} else if (
			trimmedSearchTerm?.length < numberOfCharactersForFirstSearchNumber
		) {
			setRichContentToDisplay([]);
			setInventorySearchResults(undefined);
			setPageSearchResults(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const isDisplayNavigation = type === g.NAVIGATION;

	useEffect(() => {
		if (isDisplayNavigation) {
			document.addEventListener(
				'ws-site-text-search-open-modal',
				openModal
			);
			return () => {
				document.removeEventListener(
					'ws-site-text-search-open-modal',
					openModal
				);
			};
		}
		return () => {};
	}, [isDisplayNavigation, openModal]);

	useEffect(() => {
		const trimmedSearchTerm = searchTermEmbedded.trim();

		if (
			trimmedSearchTerm?.length >= numberOfCharactersForFirstSearchNumber
		) {
			debouncedFetchEmbedded(trimmedSearchTerm);
		} else if (
			trimmedSearchTerm?.length < numberOfCharactersForFirstSearchNumber
		) {
			setRichContentToDisplay([]);
			setInventorySearchResults(undefined);
			setPageSearchResults(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTermEmbedded]);

	useEffect(() => {
		// This effect only runs once to setup specific data for this component
		if (!hasFetchedAddInfo.current && type === 'embedded') {
			fetchAddInfo();
			hasFetchedAddInfo.current = true;
		}
		if (isEmbedded && isBrowser && window) {
			const params = new URLSearchParams(window.location.search);
			if (params.has('search')) {
				setSearchTermEmbedded(params?.get('search'));
			}
		}
		if (isBrowser && window) {
			addNewRelicPageAction('wsSiteTextSearchDebug', {
				eventName: 'ws-site-text-search on page',
				type: type.toString()
			});
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let stsContainerContent = null;

	const commonEmbedDropDownFlyOutProps = {
		addInfo,
		handleChipClick,
		handleLinkClick,
		inputRef,
		inputElementRef,
		inputWidth,
		dropDownPosition,
		numberOfCharactersForFirstSearchNumber,
		noResultsLinks: NO_RESULTS_LINKS,
		noResultsRichContent,
		onRemove: removeRecentSearch,
		onReorder: reorderRecentSearches,
		pageAlias,
		windowId,
		activeInventoryTab,
		setActiveInventoryTab,
		disabledTabs
	};

	const commonDropDownFlyOutProps = {
		isOpen: modalIsOpen,
		inventoryResults: inventorySearchResults,
		pageResults: pageSearchResults,
		searchTerm,
		recentSearches,
		richContentToDisplay,
		...commonEmbedDropDownFlyOutProps
	};

	const commonInputProps = {
		inputRef,
		inputElementRef,
		openModal,
		isOpen: modalIsOpen,
		handleSubmission,
		searchTerm,
		searchTermEmbedded,
		isEmbeddedOverrideActive
	};

	switch (type) {
		case 'embedded':
			stsContainerContent = (
				<>
					{(!isEmbeddedOverrideActive || !isMobile) && (
						<InputDisplay
							{...commonInputProps}
							onClear={() => {
								setSearchTerm('');
							}}
							type={g.EMBEDDED}
							data-testid="sts-input"
							handleOnChangeInput={handleOnChangeInput}
							onInputHandler={() => {
								setIsEmbeddedOverrideActive(true);
								if (!isEmbeddedOverrideActive) {
									setSearchTerm('');
								}
							}}
						/>
					)}

					<StaticEmbedded
						{...commonEmbedDropDownFlyOutProps}
						dropDownRef={embeddedDropDownRef}
						recentSearches={recentSearches}
						setModalIsOpen={setModalIsOpen}
						handleClearHistory={handleClearHistory}
						pageResults={pageSearchResultsEmbedded}
						searchTerm={searchTermEmbedded}
						richContentToDisplay={richContentToDisplayEmbedded}
						closeModal={() => {
							closeModal();
						}}
						isDropdown={isEmbeddedOverrideActive}
					/>
					{modalIsOpen && !isMobile && isEmbeddedOverrideActive && (
						<DropDown
							{...commonDropDownFlyOutProps}
							dropDownRef={dropDownRef}
							handleClearHistory={handleClearHistory}
							setModalIsOpen={setModalIsOpen}
							onRemove={removeRecentSearch}
							inventoryResults={
								filteredInventorySearchResultsByTab
							}
							closeModal={() => {
								setIsEmbeddedOverrideActive(false);
								closeModal();
							}}
							noResultsLinks={NO_RESULTS_LINKS}
							noResultsRichContent={noResultsRichContent}
							disabledTabs={disabledTabs}
						/>
					)}
					{modalIsOpen && isMobile && isEmbeddedOverrideActive && (
						<FlyOut
							isOpen={modalIsOpen}
							onClose={() => {
								setIsEmbeddedOverrideActive(false);
								closeModal();
							}}
							onClear={() => {
								setSearchTerm('');
							}}
							openButtonRef={openButtonRef}
							closeModal={closeModal}
							inputElementRef={inputElementRef}
							handleOnChangeInput={handleOnChangeInput}
							searchTerm={searchTerm}
							searchTermEmbedded={searchTermEmbedded}
							inventoryResults={
								filteredInventorySearchResultsByTab
							}
							handleLinkClick={handleLinkClick}
							addInfo={addInfo}
							richContentToDisplay={richContentToDisplay}
							numberOfCharactersForFirstSearchNumber={
								numberOfCharactersForFirstSearchNumber
							}
							pageResults={pageSearchResults}
							recentSearches={recentSearches}
							handleClearHistory={handleClearHistoryNoClose}
							onRemove={removeRecentSearch}
							onReorder={reorderRecentSearches}
							handleChipClick={handleChipClick}
							handleSubmission={handleSubmission}
							isMobile={isMobile}
							isEmbeddedOverrideActive={isEmbeddedOverrideActive}
							noResultsLinks={NO_RESULTS_LINKS}
							noResultsRichContent={noResultsRichContent}
							activeInventoryTab={activeInventoryTab}
							setActiveInventoryTab={setActiveInventoryTab}
							disabledTabs={disabledTabs}
						/>
					)}
				</>
			);
			break;
		case 'input':
			stsContainerContent = (
				<>
					<InputDisplay
						{...commonInputProps}
						onClear={() => {
							setSearchTerm('');
						}}
						type={g.INPUT}
						data-testid="sts-input"
						handleOnChangeInput={handleOnChangeInput}
						isEmbeddedOverrideActive
					/>
					{modalIsOpen && !isMobile && (
						<DropDown
							{...commonDropDownFlyOutProps}
							dropDownRef={dropDownRef}
							handleClearHistory={handleClearHistory}
							inventoryResults={
								filteredInventorySearchResultsByTab
							}
							noResultsLinks={NO_RESULTS_LINKS}
							noResultsRichContent={noResultsRichContent}
							disabledTabs={disabledTabs}
							setModalIsOpen={setModalIsOpen}
							onRemove={removeRecentSearch}
							closeModal={() => {
								closeModal();
							}}
						/>
					)}
					{modalIsOpen && isMobile && (
						<FlyOut
							{...commonDropDownFlyOutProps}
							onClose={() => {
								setIsEmbeddedOverrideActive(false);
								closeModal();
							}}
							onClear={() => {
								setSearchTerm('');
							}}
							openButtonRef={openButtonRef}
							closeModal={closeModal}
							handleOnChangeInput={handleOnChangeInput}
							handleClearHistory={handleClearHistoryNoClose}
							handleSubmission={handleSubmission}
							isMobile={isMobile}
							isEmbeddedOverrideActive
							noResultsLinks={NO_RESULTS_LINKS}
							noResultsRichContent={noResultsRichContent}
							inventoryResults={
								filteredInventorySearchResultsByTab
							}
							disabledTabs={disabledTabs}
						/>
					)}
				</>
			);
			break;
		case 'button':
		default:
			stsContainerContent = (
				<>
					<ButtonDisplay
						openButtonRef={openButtonRef}
						openModal={openModal}
						type={type}
						data-testid="sts-input"
					/>
					{modalIsOpen && (
						<FlyOut
							{...commonDropDownFlyOutProps}
							onClose={() => {
								setIsEmbeddedOverrideActive(false);
								closeModal();
							}}
							onClear={() => {
								setSearchTerm('');
							}}
							openButtonRef={openButtonRef}
							closeModal={closeModal}
							handleOnChangeInput={handleOnChangeInput}
							handleClearHistory={handleClearHistoryNoClose}
							searchTermEmbedded={searchTermEmbedded}
							handleSubmission={handleSubmission}
							isMobile={isMobile}
							screenWidth={screenWidth}
							isEmbeddedOverrideActive
							noResultsLinks={NO_RESULTS_LINKS}
							noResultsRichContent={noResultsRichContent}
							disabledTabs={disabledTabs}
							inventoryResults={
								filteredInventorySearchResultsByTab
							}
						/>
					)}
				</>
			);
	}

	return stsContainerContent;
};

export default Container;
