import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import Chip from './Chip';

const RecentSearches = ({
	recentSearches,
	handleClearHistory,
	chipAriaLabel,
	removeAriaLabel,
	onRemove,
	onReorder,
	handleChipClick
}) => {
	const labels = useLabels();

	return (
		<div role="menu">
			<div className="d-flex justify-content-between align-items-center">
				<h4
					id="recent-searches-heading"
					className="mt-0 text-capitalize"
				>
					{labels.get('RECENT_SEARCHES')}
				</h4>
				<button
					className="btn btn-link p-0 text-danger font-weight-light btn-sm"
					onClick={handleClearHistory}
					type="button"
					aria-label={labels.get('CLEAR_SEARCH_HISTORY')}
				>
					{labels.get('CLEAR_HISTORY')}
				</button>
			</div>
			{recentSearches?.slice(0, 5).map((recentSearch) => (
				<Chip
					key={recentSearch.value}
					recentSearch={recentSearch}
					btnLabel={chipAriaLabel}
					removeLabel={removeAriaLabel}
					onRemove={onRemove}
					onReorder={onReorder}
					handleChipClick={handleChipClick}
				/>
			))}
		</div>
	);
};

RecentSearches.propTypes = {
	recentSearches: PropTypes.arrayOf(PropTypes.shape({})),
	handleClearHistory: PropTypes.func,
	chipAriaLabel: PropTypes.string,
	removeAriaLabel: PropTypes.string,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func,
	handleChipClick: PropTypes.func
};

export default RecentSearches;
