import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { useLabels, useFlags } from 'wsm-common-data';

const InventoryTabs = ({
	activeInventoryTab,
	children,
	setActiveInventoryTab,
	disabledTabs,
	showTabs
}) => {
	const labels = useLabels();
	const flags = useFlags();

	const useInventoryTabs =
		flags['enable-conditions-tabs-site-text-search'] === true;

	const tabs = [
		{ key: 'new', label: 'TAB_NEW' },
		{ key: 'used', label: 'TAB_PRE_OWNED' },
		{ key: 'certified', label: 'TAB_CERTIFIED' }
	];

	if (!useInventoryTabs || !showTabs) return children;

	return (
		<div className="mb-4 tabs tabs-fill">
			<Tabs
				onSelect={(e) => setActiveInventoryTab(e)}
				className="inventory-tabs mx-4 ddc-font-size-large"
				activeKey={activeInventoryTab}
				id="inventory-tabs"
			>
				{tabs.map((tab) => (
					<Tab
						className="py-4 ddc-font-size-base"
						title={labels.get(tab.label)}
						eventKey={tab.key}
						key={tab.key}
						disabled={disabledTabs?.some(
							(disabledTab) => disabledTab === tab.key
						)}
					>
						{children}
					</Tab>
				))}
			</Tabs>
		</div>
	);
};

InventoryTabs.propTypes = {
	disabledTabs: PropTypes.arrayOf(PropTypes.string),
	setActiveInventoryTab: PropTypes.func,
	activeInventoryTab: PropTypes.bool,
	showTabs: PropTypes.bool,
	children: PropTypes.node
};

export default InventoryTabs;
